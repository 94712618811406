import React, {ChangeEvent} from "react";
import styles from "./FullPage.module.css";
import {Content} from "../Content/Content";
import {uploadFileId} from "../UploadComponent/UploadComponent";
import {addFiles} from "../../fileSlice";
import {useAppDispatch} from "../../../app/hooks";
import {useClerk} from "@clerk/clerk-react";

export const FullPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const {signOut} = useClerk();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            dispatch(addFiles(Array.from(files)));
        }
    }

    return <div className={styles.page}>
        <img className={styles.logo} src={'./logo.svg'} alt={'logo'}/>
        <span className={styles.logout} onClick={e => signOut()}>Sign out</span>
        <input
            id={uploadFileId}
            type="file"
            accept={'.pdf'}
            className={styles.input}
            multiple={true}
            onChange={handleFileChange}
        />
        <Content handleFileChange={handleFileChange}/>
    </div>
}
