import React from 'react';
import styles from './Login.module.css'
import {SignInButton} from "@clerk/clerk-react";

export const Login = () => {

    return <div className={styles.page}>
        <img className={styles.logo} src={'./logo.svg'} alt={'logo'}/>
        <span className={styles.title}>Welcome to Digital Assistant</span>
        <SignInButton>
            <div className={styles.button}>
                <span className={styles.buttonTitle}>Sign In</span>
            </div>
        </SignInButton>
    </div>
}
