import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import './index.css'
import reportWebVitals from './reportWebVitals';
import {App} from "./features/components/App/App";
import {ClerkProvider} from "@clerk/clerk-react";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ClerkProvider publishableKey={"pk_test_ZmluZXItb2FyZmlzaC05NS5jbGVyay5hY2NvdW50cy5kZXYk"}>
                <App/>
            </ClerkProvider>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
